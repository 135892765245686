<template>
  <v-app-bar
    color="toolbar-primary"
    scroll-behavior="elevate"
    scroll-threshold="100"
    @drop.prevent="dragover = false"
    @dragover.prevent="dragover = true"
    @dragenter.prevent="dragover = true"
    @dragleave.prevent="dragover = false"
  >
    <router-link
      to="/olympialex/dashboard"
      v-if="olympiaLexView"
      @click="setRootScope()"
    >
      <v-img
        class="mx-2"
        :src="logoOlympialex"
        :min-width="100"
        style="
          padding: 5px;
          height: auto;
          width: 10vw;
          border-color: transparent;
        "
      />
    </router-link>

    <router-link
      :to="navbarIconLinkComputed"
      v-if="!olympiaLexView"
      @click="setRootScope()"
    >
      <v-img
        class="my-auto mx-2"
        :src="logoImage"
        :min-width="100"
        style="width: 10vw; height: auto"
      />
    </router-link>

    <v-spacer></v-spacer>

    <v-btn
      :title="'Help'"
      :color="accountColor"
      class="h-100 align-content-center"
      @click="showStepper()"
    >
      <v-icon>mdi-compass-outline</v-icon>
    </v-btn>

    <StepperComponent
      v-model="stepper"
      @closeGuide="this.stepper = false"
    ></StepperComponent>

    <v-btn
      :title="'Home'"
      :color="accountColor"
      class="h-100 align-content-center"
      @click="dashboardScope()"
    >
      <v-icon>mdi-home</v-icon>
    </v-btn>

    <!-- buttons for bigger screens-->
    <UploadStatusComponent
      class="d-flex h-100 align-content-center"
    ></UploadStatusComponent>

    <LanguageComponent v-if="this.$vuetify.display.mdAndUp"></LanguageComponent>

    <v-menu location="top" class="p-0 m-0" v-if="this.$vuetify.display.mdAndUp">
      <template v-slot:activator="{ props }">
        <v-btn
          class="text-none p-0"
          stacked
          v-bind="props"
          :title="$t('legenda_134')"
          :color="accountColor"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          nav
          to="/profile"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item-title>{{ $t("legenda_009") }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          nav
          style="text-decoration: none; color: inherit; cursor: pointer"
        >
          <v-list-item-title @click="logout">{{
            $t("legenda_010")
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!--menu for mobile-->
    <v-menu v-if="this.$vuetify.display.smAndDown">
      <template v-slot:activator="{ props }">
        <v-btn class="h-100 align-content-center" v-bind="props">
          <v-icon>mdi-dots-vertical</v-icon></v-btn
        >
      </template>

      <v-list dense>
        <v-list-group>
          <template v-slot:activator="{ props }">
            <v-list-item
              nav
              v-bind="props"
              :title="$t('legenda_134')"
              :color="accountColor"
              :prepend-icon="'mdi-account'"
            >
            </v-list-item>
          </template>
          <v-list-item
            nav
            style="cursor: pointer; text-decoration: none"
            @click="this.$router.push('/profile')"
            :prepend-icon="'mdi-account-settings'"
            :color="accountColor"
            :title="$t('legenda_009')"
            class="menu-list-item"
          >
          </v-list-item>

          <v-list-item
            @click="logout"
            nav
            style="cursor: pointer"
            :prepend-icon="'mdi-logout'"
            class="menu-list-item"
            :title="$t('legenda_010')"
          >
          </v-list-item>
        </v-list-group>

        <v-list-group>
          <template v-slot:activator="{ props }">
            <v-list-item
              nav
              v-bind="props"
              :title="this.$root.$i18n.locale.toUpperCase()"
              :color="accountColor"
              :prepend-icon="'mdi-web'"
            >
            </v-list-item>
          </template>
          <LanguageListComponent
            class="language-style"
            :show-icon="true"
          ></LanguageListComponent>
        </v-list-group>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import logoImgBlack from "../assets/images/legenda-logo.svg";
import logoImgWhite from "../assets/images/legenda-logo-white.svg";
import logoOlympialexImg from "../assets/images/olympialex_logo.png";
import UploadStatusComponent from "./upload/UploadStatusComponent.vue";
import { mapGetters, mapActions } from "vuex";
import LanguageListComponent from "./ui/language/LanguageListComponent.vue";
import LanguageComponent from "./ui/language/LanguageComponent.vue";
import StepperComponent from "./StepperComponent.vue";

export default {
  name: "NavBar",
  components: {
    UploadStatusComponent,
    LanguageComponent,
    LanguageListComponent,
    StepperComponent,
  },
  computed: {
    ...mapGetters({
      user: "stateUser",
      olympiaLexView: "olympiaLexView",
      isAuthenticated: "isAuthenticated",
      searchScope: "stateSearchScope",
      tree: "stateTree",
    }),
    /**
     *
     */
    accountColor() {
      return this.olympiaLexView ? "surface" : "button-secondary";
    },
    /**
     *
     */
    logoImage() {
      return this.olympiaLexView ? this.logoWhite : this.logoBlack;
    },
    /**
     *
     */
    navbarIconLinkComputed() {
      return this.isAuthenticated ? "/dashboard" : "/";
    },
  },
  data() {
    return {
      logoBlack: logoImgBlack,
      logoWhite: logoImgWhite,
      dragover: false,
      logoOlympialex: logoOlympialexImg,
      searchQuery: null,
      stepper: false,
    };
  },
  methods: {
    ...mapActions(["logOut"]),
    /**
     *
     */
    async logout() {
      const user = { ...this.user };
      await this.logOut(this.user.username);

      if (user.subscription_tier === "olympialex_review") {
        this.$store.commit("resetState");

        window.location.href =
          "https://www.olympialex.com/olympialex_review.php";
      } else {
        this.$store.commit("resetState");
        this.$router.push("/login");
      }
    },
    /**
     *
     */
    dashboardScope() {
      let dashboardLink = "/dashboard";
      if (this.searchScope.length > 0) {
        const folderToSearch =
          this.searchScope[0].type == "folder"
            ? this.searchScope[0].itemId
            : this.searchScope[0].parentId;
        dashboardLink = `/content/${folderToSearch}`;
      }

      const link = this.olympiaLexView
        ? `/olympialex/dashboard`
        : dashboardLink;
      link;

      this.$router.push(link);
    },
    setRootScope() {
      this.$store.commit("setSearchScope", this.tree[0]);
    },
    /**
     *
     */
    showStepper() {
      this.stepper = true;
    },
  },
  /**
   *
   */
  created() {
    // check state: if stepper is not in local storage, show stepper
    if (!localStorage.getItem("stepperShown")) this.stepper = true;
  },
};
</script>
<style>
.language-style .v-list-item-title {
  font-size: 0.8125rem !important;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1rem;
}
</style>
