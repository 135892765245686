<template>
  <v-form ref="input">
    <div class="password-form-style mx-auto">
      <div class="h5 p-1 m-1">{{ $t(formTitle) }}</div>

      <div>
        <alert
          :variant="alertVariant"
          :messageList="alertMessages"
          v-if="showAlert"
        ></alert>
      </div>

      <div class="">
        <v-text-field
          @keydown.space.prevent
          class="p-1 m-1 password-input-style"
          color="input-primary"
          v-model="password"
          :hint="$t('legenda_098')"
          :label="$t('legenda_100')"
          :type="showPassword ? 'text' : 'password'"
          :rules="[rules.required, rules.length]"
          :placeholder="$t('legenda_099')"
          variant="outlined"
          prepend-inner-icon="mdi-lock"
          :append-icon="passwordLength"
          :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append-inner="showPassword = !showPassword"
        ></v-text-field>

        <v-text-field
          @keydown.space.prevent
          class="p-1 m-1 password-input-style"
          color="input-primary"
          v-model="repeatedPassword"
          :hint="$t('legenda_101')"
          :label="$t('legenda_102')"
          :type="showRepeatedPassword ? 'text' : 'password'"
          :rules="[rules.passwordValidation]"
          :placeholder="$t('legenda_103')"
          variant="outlined"
          prepend-inner-icon="mdi-lock"
          :append-icon="passwordMatch"
          :append-inner-icon="showRepeatedPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append-inner="showRepeatedPassword = !showRepeatedPassword"
        ></v-text-field>

        <div style="text-align: center; margin-right: 5%">
          <v-btn
            color="button-primary"
            class="m-3 text-typo-surface"
            style="width: 60%"
            type="submit"
            :disabled="!validForm"
            >{{ buttonLabel }}</v-btn
          >
        </div>
      </div>
    </div>
  </v-form>
</template>
<script>
import AlertComponent from "../AlertComponent.vue";
export default {
  name: "PasswordFormComponent",
  components: {
    alert: AlertComponent,
  },

  props: {
    validForm: {
      type: Boolean,
      default: false,
    },
    buttonLabel: {
      type: String,
      default: "Submit",
    },
    alertMessages: {
      type: Array,
      default() {
        return [];
      },
    },
    alertVariant: {
      type: String,
      default: "warning",
    },
    showAlert: {
      type: Boolean,
      default: false,
    },
    formTitle: {
      type: String,
      default: "legenda_107",
    },
  },

  computed: {
    passwordLength() {
      return this.password && this.password.length > 7 ? "mdi-check" : "mdi";
    },
    passwordMatch() {
      return this.password === this.repeatedPassword && this.password
        ? "mdi-check"
        : "mdi";
    },
  },

  data() {
    return {
      repeatedPassword: null,
      password: null,
      isPasswordValid: false,
      showPassword: false,
      showRepeatedPassword: false,
      rules: {
        required: (value) => !!value || this.$t("legenda_104"),
        passwordValidation: () =>
          this.checkPassword() || this.$t("legenda_105"),
        length: (value) => value.length > 7 || this.$t("legenda_106"),
      },
    };
  },

  watch: {
    /**
     *
     * @param newValue
     */
    async password(newValue) {
      if (newValue) {
        await this.$refs.input.validate();
        this.onInput(this.$refs.input.isValid);
      }
    },
    /**
     *
     * @param newValue
     */
    async repeatedPassword(newValue) {
      if (newValue) {
        await this.$refs.input.validate();
        this.onInput(this.$refs.input.isValid);
      }
    },
  },
  methods: {
    /**
     *
     */
    checkPassword() {
      if (this.repeatedPassword === null) {
        return true;
      }
      return this.password === this.repeatedPassword;
    },
    /**
     *
     * @param newValue
     */
    onInput(newValue) {
      this.isPasswordValid = newValue && this.repeatedPassword !== null;
      this.$emit("passwordChanged", {
        isPasswordValid: this.isPasswordValid,
        password: this.repeatedPassword,
      });
    },
  },
};
</script>
<style>
.password-form-style {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: auto;
}

.password-input-style .v-input__control .v-field .v-field__field input {
  /* For browsers that use text-fill (e.g., Safari) */
  -webkit-text-fill-color: rgb(var(--v-theme-input-primary)) !important;
}

.password-input-style {
  min-width: 340px !important;
}

@media (max-width: 340px) {
  .password-input-style {
    min-width: 200px !important;
    max-width: -webkit-fill-available;
  }
}
</style>
