<template>
  <BaseModal
    v-model="dialog"
    hide-overlay
    class="stepper-style"
    v-on:close="closeStepper"
    :modal-class="''"
    style="max-width: 900px !important"
    :scrollable="true"
    :persistent="false"
    :contained="false"
    :showCloseBtnHeader="true"
  >
    <template v-slot:header>
      <div class="d-flex">
        <h4>
          <v-icon
            dense
            color="icon-primary"
            icon="mdi-compass-outline"
            class="p-auto"
            size="40"
          >
          </v-icon>
          {{ $t("legenda_220") }}
        </h4>
      </div>
    </template>

    <template v-slot:body>
      <v-stepper
        :mobile="mobileMode"
        elevation="2"
        v-model="currentStep"
        non-linear
      >
        <template v-slot:default="{ prev, next }">
          <v-stepper-header>
            <!-- eslint-disable-next-line vue/no-v-for-template-key -->
            <template v-for="step in guide.steps" :key="`${step.order}-step`">
              <v-stepper-item
                :title="$t(step.description)"
                :value="step.order"
                :icon="step.icon"
                :color="slideInStep ? 'success' : 'contrast'"
              ></v-stepper-item>

              <v-divider
                :opacity="1"
                v-if="step.order in [1, 2, 3]"
                :key="step.order"
              ></v-divider>
            </template>
          </v-stepper-header>

          <v-stepper-window class="m-2" v-model="selectedSlideIndex">
            <v-stepper-window-item
              v-for="feature in this.guide.featureList"
              :key="feature.id"
            >
              <v-sheet class="rounded-lg slide-style">
                <v-card style="max-width: 750px; margin: auto" elevation="0">
                  <v-card-text class="text-center text-wrap p-2 m-1 text-h6">
                    {{ $t(feature.text) }}</v-card-text
                  >
                  <v-img
                    @click="showOverlay(feature.source)"
                    style="width: 100%; height: auto; cursor: zoom-in"
                    :src="feature.source"
                  >
                  </v-img>

                  <v-overlay
                    v-model="overlay"
                    location="bottom center"
                    origin="overlap"
                    opacity="1"
                    class="d-flex justify-center align-center"
                    @click="overlay = false"
                  >
                    <v-responsive>
                      <v-img
                        :src="this.selectedImage"
                        class="overlay-style"
                        height="auto"
                        style="cursor: zoom-out"
                      ></v-img>
                    </v-responsive>
                  </v-overlay>
                </v-card>
              </v-sheet>
            </v-stepper-window-item>
          </v-stepper-window>
          <div class="d-flex flex-row align-items-center justify-space-between">
            <v-stepper-actions
              @click:next="next"
              @click:prev="prev"
              class="justify-content-center p-0 w-50"
              style="margin-left: 25%"
            >
              <template v-slot:prev="{ props }">
                <v-btn
                  :disabled="selectedSlideIndex === 0"
                  icon="mdi-chevron-left"
                  v-bind="props"
                  @click="prevClicked"
                  variant="plain"
                >
                </v-btn>

                <div>
                  {{
                    $t("legenda_232", [
                      selectedSlideIndex + 1,
                      this.guide.featureList.length,
                    ])
                  }}
                </div>
              </template>

              <template v-slot:next="{ props }">
                <v-btn
                  :disabled="selectedSlideIndex === 7"
                  icon="mdi-chevron-right"
                  v-bind="props"
                  @click="nextClicked"
                  variant="plain"
                ></v-btn>
              </template>
            </v-stepper-actions>
            <v-btn
              @click="closeStepper"
              style="right: 0"
              variant="flat"
              color="button-primary"
              v-if="selectedSlideIndex === 7"
              >{{ $t("legenda_233") }}</v-btn
            >
          </div>
        </template>
      </v-stepper>
      <!-- Image Overlay -->
    </template>
    <template v-slot:footer> <div></div> </template>
  </BaseModal>
</template>

<script>
import { mapGetters } from "vuex";
import BaseModal from "./ui/modals/BaseModal.vue";

export default {
  name: "StepperComponent",
  components: {
    BaseModal,
  },
  computed: {
    /**
     *
     */
    ...mapGetters({ language: "language" }),

    /**
     *
     */
    mobileMode() {
      return this.$vuetify.display.smAndDown;
    },
    /**
     *
     */
    currentLanguage() {
      return this.language == null ? this.$root.$i18n.locale : this.language;
    },
    /**
     *
     */
    slideInStep() {
      const step = this.guide.steps.find((step) =>
        step.features.some((item) => item.id === this.selectedSlideIndex + 1)
      );
      return this.currentStep === step.order;
    },
  },
  watch: {
    /**
     *
     * @param newValue
     */
    language(newValue) {
      if (newValue) {
        this.configureSteps(newValue);
      }
    },
  },
  methods: {
    /**
     *
     */
    prevClicked() {
      this.selectedSlideIndex--;
      if (this.selectedSlideIndex === -1)
        this.selectedSlideIndex = this.guide.featureList.length - 1;

      const step = this.guide.steps.find((step) =>
        step.features.some((item) => item.id === this.selectedSlideIndex + 1)
      );

      this.currentStep = step.order;
    },
    /**
     *
     */
    nextClicked() {
      this.selectedSlideIndex++;
      if (this.selectedSlideIndex === this.guide.featureList.length)
        this.selectedSlideIndex = 0;

      const step = this.guide.steps.find((step) =>
        step.features.some((item) => item.id === this.selectedSlideIndex + 1)
      );

      this.currentStep = step.order;
    },
    /**
     *
     */
    closeStepper() {
      this.selectedSlideIndex = 0;
      this.currentStep = 1;

      this.$emit("closeGuide");
    },
    /**
     *
     * @param locale
     */
    loadImages(locale) {
      for (let i = 0; i < this.guide.featureList.length; i++) {
        this.guide.featureList[i].source = null;
        this.guide.featureList[
          i
        ].source = require(`../assets/images/guide/${locale}/${this.guide.featureList[i].icon}`);
      }
    },
    /**
     *
     * @param locale
     */
    configureSteps(locale) {
      this.loadImages(locale);
    },

    /**
     *
     * @param image
     */
    showOverlay(image) {
      // Set the selected image and open the overlay
      this.selectedImage = image;
      this.overlay = true;
    },
  },
  data() {
    return {
      currentStep: 1,
      selectedImage: null,
      overlay: false,
      dialog: false,
      selectedSlideIndex: 0,
      guide: {
        featureList: [
          {
            text: "legenda_224",
            source: null,
            icon: "home.webp",
            id: 1,
          },
          {
            text: "legenda_225",
            source: null,
            icon: "upload_status_modal.webp",
            id: 2,
          },
          {
            text: "legenda_226",
            source: null,
            icon: "explore_dashboard.webp",
            id: 3,
          },
          {
            text: "legenda_227",
            source: null,
            icon: "document_explorer.webp",
            id: 4,
          },
          {
            text: "legenda_228",
            source: null,
            icon: "search_scope.webp",
            id: 5,
          },
          {
            text: "legenda_229",
            source: null,
            icon: "search_results.webp",
            id: 6,
          },
          {
            text: "legenda_230",
            source: null,
            icon: "highlight.webp",
            id: 7,
          },
          {
            text: "legenda_231",
            source: null,
            icon: "search_queries.webp",
            id: 8,
          },
        ],
        steps: [
          {
            description: "legenda_221",
            order: 1,
            features: [{ id: 1 }, { id: 2 }, { id: 3 }],
            icon: "mdi-cloud-upload",
          },
          {
            description: "legenda_222",
            order: 2,
            features: [{ id: 4 }, { id: 5 }],
            icon: "mdi-magnify",
          },
          {
            description: "legenda_223",
            order: 3,
            features: [{ id: 6 }, { id: 7 }, { id: 8 }],
            icon: "mdi-glasses",
          },
        ],
      },
    };
  },
  /**
   *
   */
  created() {
    // store the very first rendering of the stepper in the local storage
    if (!localStorage.getItem("stepperShown")) {
      localStorage.setItem("stepperShown", true);
    }
    this.configureSteps(this.$root.$i18n.locale);
  },
};
</script>
<style>
.slide-style {
  border-radius: 16px;
  height: 500px;
}
.pretty-wrap {
  text-wrap: wrap;
}
.overlay-style {
  width: 1100px;
}

@media (max-width: 1100px) {
  .overlay-style {
    width: 90vw;
  }
}
@media (max-width: 760px) {
  .slide-style {
    border-radius: 16px;
    height: 400px;
  }
}
@media (max-width: 630px) {
  .slide-style {
    border-radius: 16px;
    height: 350px;
  }
}

@media (max-width: 460px) {
  .slide-style {
    border-radius: 16px;
    height: 300px;
  }
}
</style>
