<template>
  <v-dialog
    hide-overlay
    v-model="showDialog"
    :scrollable="scrollable"
    location="top"
    rounded
    :persistent="persistent"
    :contained="contained"
  >
    <v-container
      class="modal-style bg-modal-primary text-typo-primary"
      :class="modalClass"
    >
      <header class="modal-header">
        <slot name="header"> This is the default title! </slot>
        <div class="d-flex">
          <v-btn
            v-if="showMinimizeBtnHeader"
            class="p-auto close-modal-button"
            icon="mdi-window-minimize"
            variant="flat"
            color="button-primary"
            size="x-small"
            @click="minimizeModal"
          ></v-btn>
          <v-btn
            v-if="showCloseBtnHeader"
            class="p-auto close-modal-button"
            icon="mdi-close"
            color="button-primary"
            variant="flat"
            size="x-small"
            @click="closeModal"
            :disabled="disableCloseBtnHeader"
          ></v-btn>
        </div>
      </header>

      <section style="flex-grow: 1; overflow-y: auto">
        <slot name="body"> This is the default body! </slot>
      </section>

      <footer>
        <slot name="footer"> This is the default footer! </slot>
        <div
          class="d-flex"
          :class="[
            showCloseBtn ? 'justify-space-between' : 'justify-content-center',
          ]"
          v-if="showCloseBtn || showConfirmBtn"
        >
          <v-btn
            v-if="showCloseBtn"
            class="mr-2"
            @click="closeModal"
            variant="text"
            color="button-secondary"
          >
            {{ $t(cancelLabel) }}
          </v-btn>
          <v-btn
            v-if="showConfirmBtn"
            class="mr-2"
            variant="flat"
            color="button-primary"
            @click="confirm"
            :disabled="!confirmEnabled"
            type="submit"
          >
            {{ $t(confirmLabel) }}
          </v-btn>
        </div>
      </footer>
    </v-container>
  </v-dialog>
</template>
<script>
export default {
  name: "BaseModal",
  methods: {
    closeModal() {
      this.$emit("close");
    },
    minimizeModal() {
      this.$emit("minimize");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
  data() {
    return { showDialog: false };
  },
  props: {
    persistent: {
      type: Boolean,
      default: false,
    },
    contained: {
      type: Boolean,
      default: true,
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    showCloseBtnHeader: {
      type: Boolean,
      default: true,
    },
    showMinimizeBtnHeader: {
      type: Boolean,
      default: false,
    },
    showCloseBtn: {
      type: Boolean,
      default: false,
    },
    showConfirmBtn: {
      type: Boolean,
      default: false,
    },
    confirmLabel: {
      type: String,
      default: "legenda_040",
    },
    cancelLabel: {
      type: String,
      default: "legenda_039",
    },
    modalClass: {
      type: String,
      default: "small-modal",
    },
    controlIconClose: {
      type: String,
      default: "mdi-close",
    },
    controlIconMinimize: {
      type: String,
      default: "mdi-window-minimize",
    },
    disableCloseBtnHeader: {
      type: Boolean,
      default: false,
    },
    confirmEnabled: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style>
.modal-style {
  margin: 0 auto;
  padding: 1%;
  border-bottom: none !important;
  max-height: 95vh;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.small-modal {
  width: auto;
  min-width: 330px;
  max-width: 30vw !important;
}
.big-modal {
  width: 100% !important;
  height: 100%;
  max-width: 95vw !important;
}

.medium-modal {
  width: 100% !important;
  height: 100%;
  max-width: 50vw !important;
}

.modal-header {
  border-bottom: none !important;
}
.close-modal-button {
  position: absolute !important;
  transform: translate(40%, -200%);
  border: 1px solid white !important;
  z-index: 9999;
}
@media (max-width: 460px) {
  .medium-modal {
    max-width: 95vw !important;
    padding: 0.5%;
    margin: 1%;
  }
}
</style>
