<template>
  <BaseModal
    v-model="isModalVisible"
    v-on:close="closeModal"
    :modal-class="'small-modal'"
  >
    <template v-slot:header>
      <div class="d-flex flex-row justify-space-between">
        <h4>{{ $t("legenda_125", 2) }}</h4>
      </div>
    </template>

    <template v-slot:body>
      <div class="tiles text-typo-primary">
        <div class="tile" @click="uploadFolders">
          <v-icon color="icon-primary" size="x-large"
            >mdi-folder-multiple</v-icon
          >
          <strong>{{ $t("legenda_117", 2) }}</strong>
        </div>

        <div class="tile" @click="uploadFiles">
          <v-icon color="icon-primary" size="x-large">mdi-file-document</v-icon>
          <strong>{{ $t("legenda_115", 1) }}</strong>
        </div>
      </div>
    </template>
    <template v-slot:footer> <br /> </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../ui/modals/BaseModal.vue";

export default {
  name: "UploadConfirmation",
  components: {
    BaseModal,
  },
  props: {},
  data() {
    return {
      isModalVisible: false,
    };
  },
  methods: {
    /**
     *
     */
    closeModal() {
      this.$emit("close");
    },
    /**
     *
     */
    uploadFolders() {
      this.$emit("uploadFolders");
    },
    /**
     *
     */
    uploadFiles() {
      this.$emit("uploadFiles");
    },
  },
};
</script>
<style>
.select-item-body {
  text-align: center;
  padding-bottom: 0;
}

.tiles {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  padding: 3%;
}
.tile {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-style: dashed;
  border-color: lightgray;
  border-width: 3px;
  align-items: center;
  border-radius: 5px;
  margin: 5%;
  padding: 10%;
  text-align: center;
  width: 200px;
  height: 150px;
  cursor: pointer;
}
</style>
