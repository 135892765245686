<template>
  <TreeView
    :items="treeReactive"
    :isCheckable="false"
    :hideGuideLines="false"
    @dropValidator="onBeforeItemDropped"
    @onExpand="fetchContent"
    @onSelect="onItemSelected"
  >
    <!-- Applying some simple styling to tree-items -->
    <template v-slot:item-prepend-icon="treeViewItem">
      <v-icon>{{ getItemIcon(treeViewItem) }}</v-icon>
    </template>
    <template v-slot:item-append="treeViewItem">
      <v-icon
        v-if="treeViewItem.inProgress"
        @click.stop="refreshContent(treeViewItem)"
        size="x-small"
        class="mx-2 align-self-center"
        >mdi-reload</v-icon
      >
    </template>
  </TreeView>
</template>

<script>
import TreeView from "vue3-tree-vue";
import "vue3-tree-vue/dist/style.css";
import { mapActions, mapGetters } from "vuex";
import cacheService from "../views/dashboard/services/dashboardService.js";

export default {
  name: "TreeViewComponent",
  components: {
    TreeView,
  },
  props: {
    tree: {
      type: Object,
      default: null,
    },
  },
  computed: {
    /**
     *
     */
    ...mapGetters({
      pdfs: "statePdfs",
      subFolders: "stateSubFolders",
      cache: "stateCache",
      searchScope: "stateSearchScope",
      progress: "stateProgress",
      polling: "statePolling",
      stateFilesToUpload: "stateFilesToUpload",
      nodesInProgress: "stateNodesInProgress",
    }),
  },
  watch: {
    tree: {
      handler(data) {
        this.treeReactive = data;
      },
      deep: true,
      immediate: true,
    },
    polling: {
      async handler(value) {
        if (value === "start") {
          const nodesInProgress = [];
          const pendingFiles = this.stateFilesToUpload.filter(
            (item) => ![200, 300].includes(item.status)
          );
          // if polling is running, it means we have pending uploads
          const distinctParents = Array.from(
            pendingFiles
              .reduce((acc, item) => {
                item.scope.meta.hierarchicalParents.forEach((parent) =>
                  acc.set(parent.folder_id, parent)
                );
                return acc;
              }, new Map())
              .values()
          );

          const parentFolderMap = new Set(
            pendingFiles.map((obj) => obj.folderId)
          );
          distinctParents.forEach((parent) => {
            let node = this.$filters.deepSearch(
              this.tree,
              parent.folder_id,
              "itemId",
              "children" //where to look for
            );
            // check if current parent is actually direct parent of any of the files
            const isParentOfFile = parentFolderMap.has(parent.folder_id);
            //set the progress to true for direct ancestors
            node.inProgress = isParentOfFile;
            node.expanded = !isParentOfFile;

            nodesInProgress.push(node);
          });
          this.$store.commit("setNodesInProgress", nodesInProgress);
        } else {
          const selectedNode = this.searchScope[0];
          for (let i = 0; i < this.nodesInProgress.length; i++) {
            const node = this.nodesInProgress[i];

            if (selectedNode.itemId === node.itemId) {
              node.expanded = false;
              node.selected = true;
            }
          }
          this.$store.commit("setNodesInProgress", []);
        }
      },
      immediate: true,
    },
  },

  data() {
    return {
      treeReactive: [],
      selectedNode: null,
    };
  },
  methods: {
    ...mapActions(["getPdfs", "getFolders"]),
    /**
     *
     * @param {*} checkedItems
     */
    onItemChecked(checkedItems) {
      console.log(checkedItems);
    },
    /**
     *
     * @param {*} item
     */
    async onItemSelected(item) {
      if (this.selectedNode) {
        this.selectedNode.selected = false;
      }

      if (item.children.length > 0 && item.children[0].id === -1) {
        await this.fetchContent(item);
      }
      // emit the change to the parents
      this.$emit("itemSelected", item);

      item.selected = true;
      this.selectedNode = item;
    },
    /**
     * Occurs when an element from the search scope tree is expanded. Same for all cases ->move it
     * @param {*} item
     */
    async fetchContent(item) {
      //check if the content is already there in the cache

      let cachedContent = this.cache[item.itemId];

      // if not, fetch it and replace the dummy children
      if (
        cachedContent == null ||
        this.nodesInProgress.find((el) => el.itemId === item.itemId)
      ) {
        item.children = [];
        cachedContent = await cacheService.setCache(item.itemId);
      }

      // set the childen to the node
      this.$store.commit("setChildNodes", {
        parentNode: item,
        nodes: cachedContent,
      });
    },

    /**
     *
     * @param {*} droppedItem
     * @param {*} dropHost
     */
    onBeforeItemDropped(droppedItem, dropHost) {
      // dropHost == undefined means dropping at the root of the tree.

      // Here you can specify any kind of drop validation you will like.
      // this function should return true if the drop operation is valid.

      if (dropHost.type !== "playlist") return false;
      return true;
    },

    /**
     *
     * @param {*} item
     */
    async refreshContent(item) {
      let itemReactive = this.$filters.deepSearch(
        this.tree,
        item.itemId,
        "itemId",
        "children" //where to look for
      );
      // show the icon for as long as polling is running
      itemReactive.inProgress = this.polling === "start";
      const cachedContent = await cacheService.setCache(itemReactive.itemId);

      this.$store.commit("setChildNodes", {
        parentNode: itemReactive,
        nodes: cachedContent,
      });
    },
    /**
     *
     * @param {*} item
     */
    getItemIcon(item) {
      if (item.type === "folder") {
        if (
          this.nodesInProgress.map((item) => item.itemId).includes(item.itemId)
        ) {
          return "mdi-folder-refresh-outline";
        } else {
          return "mdi-folder-outline";
        }
      }
    },
  },
  async created() {
    this.treeReactive = this.tree;
    if (this.searchScope[0].type === "folder")
      await this.fetchContent(this.searchScope[0]);

    let scopeInTree = this.$filters.deepSearch(
      this.tree,
      this.searchScope[0].itemId,
      "itemId",
      "children" //where to look for
    );
    this.selectedNode = scopeInTree;
  },
};
</script>

<style scoped></style>
