<template>
  <v-row class="bg-page-secondary h-100 m-auto">
    <v-col class="m-auto" cols="12">
      <div class="d-flex flex-column" style="justify-content: space-between">
        <v-sheet flat class="register-form">
          <v-img
            class="mx-auto my-1"
            :src="logoImage"
            style="width: 160px"
          ></v-img>

          <div class="text-h5 font-weight-bold" style="text-align: center">
            {{ $t("legenda_142") }}
          </div>
          <user_consent
            :legal="legal"
            v-on:legal="(value) => (legal = value)"
            :marketing="marketing"
            v-on:marketing="(value) => (marketing = value)"
            :profiling="profiling"
            v-on:profiling="(value) => (profiling = value)"
            :training="training"
            v-on:training="(value) => (training = value)"
          ></user_consent>

          <div class="p-1 password-container">
            <password_form
              @submit.prevent="submit"
              :showAlert="showAlert"
              :alertMessages="alertMessages"
              :buttonLabel="$t('legenda_143')"
              :validForm="validForm"
              v-on:passwordChanged="(value) => setPassword(value)"
            ></password_form>
          </div>
          <div class="mx-auto" style="text-align: center">
            <i class="d-inline">
              <i18n-t keypath="legenda_097">
                <template #support>
                  &nbsp;
                  <a :href="'mailto:info@metisai.it'" target="_self">{{
                    $t("legenda_087")
                  }}</a>
                </template>
              </i18n-t>
            </i>
          </div>
        </v-sheet>

        <div>
          <metis_footer :fontColor="'text-typo-primary'"></metis_footer>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import FooterComponent from "../../../components/FooterComponent.vue";
import UserConsentComponent from "../../legal/UserConsentComponent.vue";
import logoImg from "../../../assets/images/legenda_logo_brand_medium.png";
import PasswordFormComponent from "../../../components/credentials/PasswordFormComponent.vue";

import { mapActions } from "vuex";

export default {
  name: "RegisterView",

  components: {
    metis_footer: FooterComponent,
    user_consent: UserConsentComponent,
    password_form: PasswordFormComponent,
  },
  props: ["token"],

  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      alertMessages: [],
      showAlert: false,
      legal: false,
      marketing: false,
      profiling: false,
      training: false,
      logoImage: logoImg,
      validPassword: "",
      isPasswordValid: false,
    };
  },

  computed: {
    validForm() {
      return this.legal && this.isPasswordValid;
    },
  },

  methods: {
    /**
     *
     */
    ...mapActions(["register"]),
    ...mapActions(["logIn"]),
    /**
     *
     */
    async submit() {
      try {
        let response = await this.register({
          token: this.token,
          user: this.user,
          legal: this.legal,
          marketing: this.marketing,
          profiling: this.profiling,
          training: this.training,
        });

        try {
          // log in
          this.user.username = response.data.username;
          response = await this.logIn(this.user);

          //go to dashboard
          this.$router.push("/dashboard");
        } catch (error) {
          this.showAlert = true;
          this.alertMessages = [
            {
              text: "legenda_025",
              links: [
                {
                  href: "mailto:info@metisai.it",
                  placeholder: this.$t("legenda_087"),
                  openInTab: false,
                },
              ],
            },
          ];
        }
      } catch (error) {
        const text =
          error.response && typeof error.response.data.detail === "string"
            ? { text: error.response.data.detail }
            : {
                text: "legenda_086",
                links: [
                  {
                    href: "mailto:info@metisai.it",
                    placeholder: this.$t("legenda_087"),
                    openInTab: false,
                  },
                ],
              };
        this.alertMessages = [text];
        this.showAlert = true;
      }
    },
    /**
     *
     * @param {*} passwordObject
     */
    setPassword(passwordObject) {
      this.isPasswordValid = passwordObject.isPasswordValid;
      this.user.password = passwordObject.password;
    },
  },
};
</script>
<style>
.register-form {
  padding: 4%;
  margin: auto;
  width: 95%;
  max-width: 900px;
  min-width: 350px;
}
.password-container {
  width: fit-content;
  align-items: flex-start;
  margin: 0 auto;
}
@media (max-width: 340px) {
  .password-container {
    width: auto;
  }
}
</style>
