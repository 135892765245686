<template>
  <div>
    <v-sheet
      class="d-flex align-center justify-center flex-wrap text-center mx-auto"
      rounded
      height="fit"
      width="fit"
      :color="uploadEnabled ? 'component-primary' : 'neutral-lighten-2'"
    >
      <v-tooltip
        location="center"
        :text="$t('legenda_250')"
        v-model="showTooltip"
        :open-on-hover="false"
        :open-on-click="true"
      >
        <template v-slot:activator="{ props }">
          <div
            v-bind="props"
            class="w-100"
            @drop.prevent="onDrop($event)"
            @dragover.prevent="dragover = uploadEnabled"
            @dragenter.prevent="dragover = uploadEnabled"
            @dragleave.prevent="dragover = false"
            @mouseenter.prevent="showTooltip = !uploadEnabled"
            @mouseleave.prevent="showTooltip = false"
            @click="showTooltip = !uploadEnabled"
          >
            <v-card
              flat
              style="
                border-style: dashed;
                border-color: lightgray;
                border-width: 3px;
              "
              color="transparent"
              class="p-1 w-100"
              :class="[
                { 'bg-grey-lighten-2': dragover },
                uploadEnabled ? 'cursor-pointer' : 'cursor-not-allowed',
              ]"
            >
              <v-card-text border="solid" class="p-0">
                <div
                  class="d-flex flex-column align-items-center"
                  :class="[]"
                  dense
                  @click="onAddFiles"
                >
                  <v-icon
                    dense
                    :color="uploadEnabled ? 'contrast' : 'neutral-darken-1'"
                    icon="mdi-cloud-upload"
                    class="m-3 p-auto"
                    size="40"
                  >
                  </v-icon>
                  <p
                    class="d-inline"
                    :class="{
                      'color-disabled': !uploadEnabled,
                    }"
                  >
                    <i18n-t keypath="legenda_121">
                      <!-- Slot for the first word to be bolded -->
                      <template #firstPart>
                        <strong>{{ $t("legenda_251") }}&nbsp;</strong>
                      </template>
                      <!-- Slot for the second word to be bolded -->
                      <template #secondPart>
                        <strong>&nbsp;{{ $t("legenda_252") }}&nbsp;</strong>
                      </template>
                    </i18n-t>
                  </p>

                  <input
                    ref="fileUploader"
                    type="file"
                    @change="onFilesPicked"
                    :accept="acceptedFormats"
                    multiple
                    hidden
                  />
                  <input
                    ref="folderUploader"
                    type="file"
                    @change="onFoldersPicked"
                    :accept="acceptedFormats"
                    webkitdirectory
                    directory
                    hidden
                  />
                </div>
              </v-card-text>
            </v-card>
          </div>
        </template>
      </v-tooltip>
    </v-sheet>

    <fileUploadModal
      v-if="uploadFileModal"
      :title="uploadFileModalTitle"
      :content="this.checkModalText"
      :tableConfigValid="tableConfigValid"
      :tableConfigInvalid="tableConfigInvalid"
      v-model="uploadFileModal"
      v-on:ok="upload"
      v-on:cancel="cancelUpload"
      v-on:removeFile="removeFile"
    ></fileUploadModal>
    <div>
      <uploadConfirmationModal
        class="d-flex w-auto m-auto"
        v-model="uploadConfirmModal"
        v-on:close="uploadConfirmModal = false"
        v-on:uploadFiles="onUploadFilesClicked"
        v-on:uploadFolders="onUploadFoldersClicked"
      ></uploadConfirmationModal>
    </div>
  </div>
</template>

<script>
import { getFileSize } from "../../utils/fileUtils.js";
import { supportedFileTypes } from "./utils/fileFormats.js";
import { mapGetters } from "vuex";
import FileUploadModal from "./FileUploadModal.vue";
import UploadConfirmationModal from "./UploadConfirmationModal.vue";

export default {
  name: "FileUploadComponent",
  components: {
    fileUploadModal: FileUploadModal,
    uploadConfirmationModal: UploadConfirmationModal,
  },
  props: {
    filesQueued: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showTooltip: false,
      validFile: false,
      invalidFiles: [],
      dragover: false,
      filesToUpload: [],
      checkModalText: "",
      uploadFileModal: false,
      uploadFileModalTitle: "legenda_120",
      uploadConfirmModal: false,
      acceptedFormats: supportedFileTypes,
      uploadReason: [
        {
          reason: "size",
          text: this.$t("legenda_108"),
        },
        {
          reason: "duplicateFile",
          text: this.$t("legenda_109"),
        },
        {
          reason: "duplicateFolder",
          text: this.$t("legenda_110"),
        },
        { reason: "format", text: this.$t("legenda_111") },
      ],
      tableConfigInvalid: {
        controlButtons: [],
        header: [
          { name: "legenda_112", descr: "file" },
          { name: "legenda_113", descr: "reason" },
        ],
        content: [],
        actions: [],
      },
      tableConfigValid: {
        controlButtons: [],
        header: [
          { name: "legenda_112", descr: "filePath" },
          {
            name: "legenda_078",
            descr: "completeSize",
          },
          {
            name: "",
            descr: "action",
            center: true,
            width: "10%",
          },
        ],
        content: [],
        actions: [
          {
            action: "removeFile",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({ searchScope: "stateSearchScope", cache: "stateCache" }),
    fileUploadText() {
      return "legenda_121";
    },
    uploadEnabled() {
      return this.searchScope[0]?.permissions === "write";
    },
    showTdooltip() {
      return this.uploadEnabled === false;
    },
  },

  methods: {
    /**
     *
     */
    onUploadFilesClicked() {
      this.$refs.fileUploader.click();
    },
    /**
     *
     */
    onUploadFoldersClicked() {
      this.$refs.folderUploader.click();
    },
    /**
     *
     */
    onFilesPicked(event) {
      const files = event.target.files;
      this.uploadFileModalTitle = "legenda_115";
      this.invalidFiles = [];
      this.clearData();

      this.prepareFiles(files);
    },
    /**
     *
     */
    onFoldersPicked(event) {
      const files = event.target.files;
      this.uploadFileModalTitle = "legenda_117"; //elkasp translations

      this.invalidFiles = [];
      this.clearData();

      //before we check the files, let's check the folders; if there are duplicates, dont even parse their content
      this.checkEntriesWithParentPath(files);
    },
    /**
     * Gets the root folder of a file's path
     * @param {*} item
     */
    getRootFolder(item) {
      if (this.dragover) {
        const pathArray = item.fullPath.split("/");
        const root = pathArray[1];
        return root;
      } else {
        const root = item.webkitRelativePath.split("/")[0];
        return root;
      }
    },
    /**
     *
     * @param {*} files
     */
    checkEntriesWithParentPath(files) {
      this.setSelectedFiles(files);

      //check duplicates
      this.selectedFiles.forEach((file) => {
        this.checkParentFolder(file);
      });

      //filter them out
      let filesWithValidParents = this.selectedFiles;

      if (this.invalidFiles.length > 0) {
        const el = this.selectedFiles.filter((file) => {
          !this.invalidFiles.find((invalid) => {
            const rootFolder = this.getRootFolder(file);
            const searchNAme = invalid.file;
            rootFolder === searchNAme;
          });
        });
        filesWithValidParents = el;
      }
      //do file check
      this.prepareFiles(filesWithValidParents);
    },
    /**
     *
     */
    async onDrop(event) {
      if (!this.uploadEnabled) return;
      const files = event.dataTransfer.items;
      this.uploadFileModalTitle = "legenda_116"; //elkasp translations

      this.invalidFiles = [];
      this.clearData();
      const fileEntries = await this.getAllFileEntries(files);
      this.prepareFiles(fileEntries);
      this.dragover = false;
    },
    /**
     * https://stackoverflow.com/questions/3590058/does-html5-allow-drag-drop-upload-of-folders-or-a-folder-tree
     * @param {*} dataTransferItemList
     */
    // Drop handler function to get all files
    async getAllFileEntries(dataTransferItemList) {
      let fileEntries = [];
      // Use BFS to traverse entire directory/file structure
      let queue = [];
      // Unfortunately dataTransferItemList is not iterable i.e. no forEach
      for (let i = 0; i < dataTransferItemList.length; i++) {
        // Note webkitGetAsEntry a non-standard feature and may change
        // Usage is necessary for handling directories
        const entry = dataTransferItemList[i].webkitGetAsEntry();
        queue.push(entry);
      }
      while (queue.length > 0) {
        let entry = queue.shift();
        if (entry.isFile) {
          const fileObject = await this.readFilePromise(entry);
          //keep fullPath, we need it for checks laters
          fileObject.fullPath = entry.fullPath;
          fileEntries.push(fileObject);
        } else if (entry.isDirectory) {
          //check here if directory exists in subfolders; if yes, don't bother parsing it
          const folderExists = await this.checkParentFolder(entry);
          if (!folderExists) {
            const content = await this.readAllDirectoryEntries(
              entry.createReader()
            );
            for (const entry of content) {
              queue.push(entry);
            }
          }
        }
      }
      return fileEntries;
    },
    /**
     * Method that wraps in the promise the reading of a file.
     * Can be invoked with async-await.
     * @param {*} file
     */
    readFilePromise(file) {
      return new Promise((resolve, reject) => {
        file.file((file) => {
          var fr = new FileReader();
          fr.onload = () => {
            resolve(file);
          };
          fr.onerror = reject;
          fr.readAsDataURL(file);
        });
      });
    },
    /**
     * https://stackoverflow.com/questions/3590058/does-html5-allow-drag-drop-upload-of-folders-or-a-folder-tree
     * @param {*} directoryReader
     */
    // Get all the entries (files or sub-directories) in a directory
    // by calling readEntries until it returns empty array
    async readAllDirectoryEntries(directoryReader) {
      let entries = [];
      let readEntries = await this.readEntriesPromise(directoryReader);
      while (readEntries.length > 0) {
        entries.push(...readEntries);
        readEntries = await this.readEntriesPromise(directoryReader);
      }
      return entries;
    },

    /**
     * https://stackoverflow.com/questions/3590058/does-html5-allow-drag-drop-upload-of-folders-or-a-folder-tree
     * @param {*} directoryReader
     */
    // Wrap readEntries in a promise to make working with readEntries easier
    // readEntries will return only some of the entries in a directory
    // e.g. Chrome returns at most 100 entries at a time
    async readEntriesPromise(directoryReader) {
      try {
        return await new Promise((resolve, reject) => {
          directoryReader.readEntries(resolve, reject);
        });
      } catch (err) {
        console.log(err);
      }
    },

    /**
     *
     */
    onAddFiles() {
      this.uploadConfirmModal = this.searchScope[0].permissions === "write";
    },
    /**
     *
     * @param {*} files
     */
    prepareFiles(files) {
      this.clearData();
      this.setSelectedFiles(files);
      this.setValidFiles();

      if (this.invalidFiles.length > 0) {
        this.tableConfigInvalid.content = this.invalidFiles;
        this.checkModalText = "legenda_119";
      }
      this.resetUploader();
      this.uploadFileModal = true;
    },
    /**
     * check sequentially file name and file size to optimize check process
     */
    isFileValid(file) {
      if (this.checkFileExists(file)) {
        return false;
      }
      if (!this.checkFileFormat(file)) {
        return false;
      }
      if (!this.checkFileSize(file)) {
        return false;
      }
      return true;
    },
    /**
     *
     */
    checkFileSize(fileToUpload) {
      let fileSize = getFileSize(fileToUpload.size, "MB");
      //check if file size is less than 100MB
      const hasValidSize = fileSize.size < 100;
      if (!hasValidSize) {
        const uploadReasonObject = this.uploadReason.find(
          (reason) => reason.reason == "size"
        );
        const isFileInvalid = this.invalidFiles.some(
          (invalidFile) => invalidFile.file.trim() === fileToUpload.name.trim()
        );
        if (isFileInvalid) {
          return hasValidSize;
        }
        this.invalidFiles.push({
          file: fileToUpload.name,
          reason: uploadReasonObject.text,
        });
      }
      return hasValidSize;
    },
    /**
     *
     */
    checkFileExists(file) {
      let existsInStateFiles = false;

      //if file is part of a folder to be uploaded, no need to check; folder is by now unique, file is already unique
      if (this.getRootFolder(file)) {
        return existsInStateFiles;
      }
      //otherwise, if it is from click upload or single file drag and drop, check it
      const name = file.name;
      const pdfs = this.cache[this.searchScope[0].itemId].filter((element) => {
        return element.type == "document";
      });
      if (pdfs) {
        existsInStateFiles = pdfs
          ? pdfs.some((pdf) => pdf.name.trim() === name.trim())
          : false;
      }

      if (existsInStateFiles) {
        const uploadReasonObject = this.uploadReason.find(
          (reason) => reason.reason === "duplicateFile"
        );
        this.invalidFiles.push({ file: name, reason: uploadReasonObject.text });
      }
      return existsInStateFiles;
    },
    /**
     *
     */
    checkFileFormat(file) {
      const fileFormat = file.name.split(".").pop().toLowerCase();
      const acceptedFormat = this.acceptedFormats.includes(fileFormat);

      if (!acceptedFormat) {
        const name = file.name;
        if (name === ".ds_store") return acceptedFormat;
        const uploadReasonObject = this.uploadReason.find(
          (reason) => reason.reason === "format"
        );
        this.invalidFiles.push({ file: name, reason: uploadReasonObject.text });
      }

      return acceptedFormat;
    },
    /**
     *
     * @param {*} file
     */
    checkParentFolder(item) {
      //check that file has no prefix, and if it does, it is not one of the state subFolders
      let topFolder = this.getRootFolder(item);
      const subFolders = this.cache[this.searchScope[0].itemId].filter(
        (element) => {
          return element.type == "folder";
        }
      );
      const folderExists = subFolders
        ? subFolders.some(
            (folder) =>
              folder.name.trim().toLowerCase() == topFolder.trim().toLowerCase()
          )
        : false;
      if (folderExists) {
        const uploadReasonObject = this.uploadReason.find(
          (reason) => reason.reason === "duplicateFolder"
        );
        this.invalidFiles.push({
          file: topFolder,
          reason: uploadReasonObject.text,
        });
      }

      return folderExists;
    },
    /**
     *
     */
    setValidFiles() {
      this.selectedFiles.forEach((file) => {
        if (this.isFileValid(file)) {
          // in mb for the calculations for the gui (in MB)
          const fileSize = getFileSize(file.size, "MB");
          // the file size to be stored in the db
          //filePath will be displayed on the modal to notify user where file resides
          const root = this.getRootFolder(file);
          let filePath = "/" + file.name;
          if (root) {
            filePath = file.webkitRelativePath
              ? "/" + file.webkitRelativePath
              : file.fullPath;
          }
          //remove file and last / from path
          const parentPath = filePath.replace(file.name, "").replace(/.$/, "");
          this.filesToUpload.push({
            parentPath: parentPath,
            filePath: filePath,
            filename: file.name,
            file: file,
            size: fileSize.size,
            status: 0,
            scope: this.searchScope[0],
            completeSize: fileSize.completeSize,
            actions: [
              {
                actionName: "removeFile",
                actionEnabled: true,
                icon: "mdi-delete-outline",
                title: "legenda_128",
              },
            ],
          });
        }
      });
      this.tableConfigValid.content = this.filesToUpload;
    },
    /**
     *
     */
    setSelectedFiles(files) {
      this.selectedFiles = [];
      for (let i = 0; i < files.length; i++) {
        this.selectedFiles.push(files[i]);
      }
    },
    /**
     *
     */
    removeFile(fileName) {
      // Find the index of the file
      const index = this.filesToUpload.findIndex(
        (file) => file.filename === fileName
      );
      // If file is in uploaded files remove it
      if (index > -1) {
        this.filesToUpload.splice(index, 1);
        this.tableConfigValid.content = this.filesToUpload;
      }
    },
    /**
     *
     */
    upload() {
      // If there aren't any files to be uploaded do nothing
      if (this.filesToUpload.length > 0) {
        // Send uploaded files to parent component
        this.uploadFileModal = false;
        this.uploadConfirmModal = false;
        this.$emit("filesUploaded", this.filesToUpload);
        //todo notify user he will receive a message in dashboard when files are uploaded
      }
    },
    /**
     *
     */
    cancelUpload() {
      this.uploadFileModal = false;
      this.resetUploader();
      this.clearData;
    },
    /**
     *
     */
    resetUploader() {
      this.$refs.fileUploader.value = null;
      this.$refs.folderUploader.value = null;
      //the array representation of the file input
      this.selectedFiles = [];
    },
    /**
     *
     */
    clearData() {
      this.selectedFiles = [];
      this.filesToUpload = [];
      //this.invalidFiles = [];
      this.tableConfigInvalid.content = [];
      this.tableConfigValid.content = [];
    },
  },
  watch: {
    filesQueued() {
      this.resetUploader();
      this.clearData();
      this.invalidFiles = [];
    },
  },
};
</script>
<style>
.disabled-background {
  background: repeating-linear-gradient(
    135deg,
    rgb(var(--v-theme-surface)),
    rgb(var(--v-theme-surface)) 10px,
    rgb(var(--v-theme-neutral)) 10px,
    rgb(var(--v-theme-neutral)) 20px
  ) !important;
  z-index: 999;
}
.color-disabled {
  color: #d3d3d3 !important;
}
</style>
