import axiosWrapper from "../../plugins/axios/axiosWrapper";

const getDefaultFolderState = () => {
  return {
    homeId: null,
    subFolders: null,
    currentFolderId: null,
    breadcrumbs: [],
  };
};

// initial state
const state = getDefaultFolderState();

const getters = {
  stateHomeId: (state) => state.homeId,
  stateSubFolders: (state) => state.subFolders,
  stateCurrentFolderId: (state) => state.currentFolderId,
  stateBreadcrumbs: (state) => state.breadcrumbs,
};

const actions = {
  /**
   *
   */
  async home({ commit, state }) {
    let data = await axiosWrapper.get("/folder/home", null, null, {
      "Content-Type": "application/json",
    });

    commit("setHomeId", data.data.elements[0].folder_id);
    commit("setCurrentFolderId", state.homeId);
    return data.data.elements[0];
  },

  /***
   *
   */
  async getFolders({ commit }, parent_folder_id) {
    let data = await axiosWrapper.get(
      "/folder/",
      null,
      { parent_folder_id: parent_folder_id },
      { "Content-Type": "application/json" }
    );

    commit("setSubFolders", data.data.elements);
  },

  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  // eslint-disable-next-line no-empty-pattern
  async deleteFolder({}, { folderId }) {
    await axiosWrapper.delete(
      `/folder/`,
      null,
      { folder_id: folderId },
      { "Content-Type": "application/json" }
    );
  },

  /***
   *
   */
  async storeFolder(
    // eslint-disable-next-line no-empty-pattern
    {},
    { parentFolderId, folderName, permissions, folderType, folderStatus }
  ) {
    const newFolderPayload = {
      parent_folder_id: parentFolderId,
      folder_name: folderName,
      permissions: permissions,
      folder_type: folderType,
      status: folderStatus,
    };

    const response = await axiosWrapper.post(`/folder/`, newFolderPayload);
    return response.data.message;
  },
  /***
   *
   */
  async shareFolder({ rootState }, { source_folder_id, to_user_username }) {
    return await axiosWrapper.post(
      `/folder/:user/share_folder`,
      {
        source_folder_id: source_folder_id,
        to_user_username: to_user_username,
      },
      { user: rootState.users.user.username }
    );
  },

  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  // eslint-disable-next-line no-empty-pattern
  async renameFolder({}, { folderId, newName }) {
    let formData = {
      new_name: newName,
    };
    await axiosWrapper.putForm(`/folder/:folder_id/rename`, formData, {
      folder_id: folderId,
    });
  },
  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  // eslint-disable-next-line no-empty-pattern
  async moveFolder({}, { folderId, targetFolderId }) {
    await axiosWrapper.put(`/folder/move`, {
      folder_id: folderId,
      target_folder_id: targetFolderId,
    });
  },
};

const mutations = {
  /**
   *
   * @param {*} state
   */
  setHomeId(state, folderId) {
    state.homeId = folderId;
  },
  /**
   *
   * @param {*} state
   * @param {*} data
   */
  setSubFolders(state, data) {
    state.subFolders = data;
  },

  /**
   *
   * @param {*} state
   * @param {*} folderId
   */
  setCurrentFolderId(state, folderId) {
    state.currentFolderId = folderId;
  },
  /**
   *
   * @param {*} state
   * @param {*} breadcrumbs
   */
  setBreadcrumbs(state, breadcrumbs) {
    if (breadcrumbs instanceof Array) {
      state.breadcrumbs = breadcrumbs;
    } else {
      if (state.breadcrumbs) {
        state.breadcrumbs.push(breadcrumbs);
      } else {
        state.breadcrumbs = new Array(breadcrumbs);
      }
    }
  },

  /**
   *
   * @param {*} state
   * @param {*} breadcrumbs
   */
  resetBreadcrumb(state, breadcrumbs) {
    state.breadcrumbs = breadcrumbs;
  },

  /**
   *
   * @param {*} state
   */
  resetFolderState(state) {
    Object.assign(state, getDefaultFolderState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
