<template>
  <v-dialog
    hide-overlay
    v-model="dialog"
    class="m-auto text-primary text-typo-primary bg-modal-primary"
    scrollable
    style="width: 95vw"
    location="top"
    persistent
    ref="diag"
  >
    <v-card>
      <v-card-title class="pt-4"
        ><h3>{{ $t(title, totalFileCount >= 2 ? 2 : 1) }}</h3></v-card-title
      >
      <v-card-text class="d-flex flex-column h-100">
        <h4 style="flex: 1 0 auto">
          {{ $t("legenda_122", { n: totalFileCount, items: adaptedText }) }}.
        </h4>

        <div v-if="showInvalidFilesTable" style="flex: 2 0 auto">
          <alert
            :variant="'warning'"
            :messageList="adaptedTextInvalid"
            :color="'custom-warning'"
          ></alert>

          <legendaTable
            v-if="tableConfigInvalid"
            style="overflow-y: auto"
            :customTableStyle="{ tableLightStyle: true }"
            :tableConfig="tableConfigInvalid"
            :density="'compact'"
            :mxHeight="calcHeight"
          ></legendaTable>
          <v-divider></v-divider>
        </div>

        <div v-if="showValidFilesTable" style="flex: 2 0 auto">
          <alert
            :variant="'success'"
            :messageList="adaptedTextValid"
            ref="alertValid"
          ></alert>

          <legendaTable
            :customTableStyle="{ tableLightStyle: true }"
            :tableConfig="tableConfigValid"
            style="overflow-y: auto"
            :density="'compact'"
            v-on:removeFile="removeFile"
            :mxHeight="calcHeight"
          ></legendaTable>

          <div class="p-2">
            <strong>
              {{ $t("legenda_123") }}
              {{ totalSize }}MB</strong
            >
          </div>
          <v-divider></v-divider>
        </div>
        <div class="p-1" style="flex: 1 0 auto">
          <strong>
            {{ $t("legenda_124") }}
            {{ availableSpace }}MB</strong
          >
        </div>
        <div v-if="limitExceeded" style="flex: 1 0 auto">
          <alert
            :variant="'warning'"
            :messageList="alertMessages"
            :color="'custom-warning'"
          ></alert>
        </div>
      </v-card-text>

      <v-card-actions class="m-1 p-2 justify-space-between">
        <v-btn color="button-secondary" variant="plain" @click="onCancel">{{
          $t(cancelButtonLabel)
        }}</v-btn>

        <v-btn
          variant="flat"
          color="button-primary"
          @click="onOk"
          :disabled="!canUpload"
          >{{ $t(okButtonLabel) }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AlertComponent from "../AlertComponent.vue";
import TableComponent from "../TableComponent.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "FileUploadModal",
  components: {
    legendaTable: TableComponent,
    alert: AlertComponent,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },

    okButtonLabel: {
      type: String,
      default: "legenda_040",
    },
    cancelButtonLabel: {
      type: String,
      default: "legenda_039",
    },
    tableConfigInvalid: {},
    tableConfigValid: {},
  },
  computed: {
    ...mapGetters({ freeSpace: "freeSpace", user: "stateUser" }),

    calcHeight() {
      return window.innerHeight / 2 - 100;
    },
    /**
     *
     */
    adaptedTextInvalid() {
      return [
        {
          text: this.$t(
            "legenda_093",
            this.tableConfigInvalid.content.length > 1 ? 2 : 1
          ),
        },
      ];
    },
    /**
     *
     */
    adaptedTextValid() {
      return [
        {
          text: this.$t(
            "legenda_092",
            this.tableConfigValid.content.length > 1 ? 2 : 1
          ),
        },
      ];
    },
    adaptedText() {
      const plural =
        this.tableConfigInvalid.content.length > 1 ||
        this.tableConfigValid.content.length > 1 ||
        this.totalFileCount === 0
          ? 1
          : 2;
      return plural;
    },
    totalFileCount() {
      const fileCountInvalid = this.tableConfigInvalid
        ? this.tableConfigInvalid.content.length
        : 0;
      const fileCountValid = this.tableConfigValid
        ? this.tableConfigValid.content.length
        : 0;
      return fileCountInvalid + fileCountValid;
    },
    availableSpace() {
      //we need more or less to have at least half of the db space reserved for the pdfs!
      return this.freeSpace;
    },

    totalSize() {
      let totalSize = 0;
      this.tableConfigValid.content.forEach((file) => {
        totalSize += file.size;
      });
      return totalSize.toFixed(2);
    },
    limitExceeded() {
      //limit is exceeded when there is less than half left
      return this.availableSpace < this.totalSize;
    },
    alertMessages() {
      return [{ text: "legenda_091" }];
    },
    canUpload() {
      return !this.limitExceeded && this.showValidFilesTable;
    },
    showInvalidFilesTable() {
      return this.tableConfigInvalid.content.length > 0;
    },
    showValidFilesTable() {
      return (
        this.tableConfigValid.content &&
        this.tableConfigValid.content.length > 0
      );
    },
  },

  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    ...mapActions(["getFreeSpace"]),

    onOk() {
      this.$emit("ok");
    },
    onCancel() {
      this.$emit("cancel");
    },
    removeFile(file) {
      this.$emit("removeFile", file.filename);
    },
  },
  watch: {
    tableConfigValid: {
      handler() {},
      deep: true,
    },
  },
  async created() {
    await this.getFreeSpace(this.user.username);
  },
};
</script>

<style>
.tableLightStyle {
  border: solid;
  border-color: whitesmoke;
  border-width: medium;
  left: 0;
  right: 0;
  width: 100%;
}
</style>
