import store from "../../../store";
import { sortFolderContent } from "../../../filters/treeFilter";
/**
 *
 * @param {*} folderId
 */

class CacheService {
  /**
   *
   * @param {*} folderId
   * @returns
   */
  async setCache(folderId) {
    await store.dispatch("getPdfs", folderId);
    await store.dispatch("getFolders", folderId);

    // sort the table for the cache
    const sortedFolderContent = sortFolderContent(
      store.getters.statePdfs,
      store.getters.stateSubFolders
    );
    // here set the cache
    store.commit("setCache", {
      folderId: folderId,
      content: sortedFolderContent,
    });

    return sortedFolderContent;
  }
  /**
   *
   * @param {*} folderId
   */
  remove(folderId) {
    store.commit("removeFromCache", { folderId: folderId });
  }
}

const cacheService = new CacheService();

export default cacheService;
