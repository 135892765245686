<template>
  <BaseModal
    v-model="uploadInProgress"
    v-on:close="closeModal"
    v-on:minimize="minimizeModal"
    :modal-class="'big-modal'"
    :scrollable="true"
    :persistent="false"
    :contained="false"
    :showMinimizeBtnHeader="true"
    :showCloseBtnHeader="false"
  >
    <template v-slot:header>
      <div class="d-flex">
        <h4>
          <v-icon
            dense
            color="icon-primary"
            icon="mdi-cloud-upload"
            class="p-auto"
            size="40"
          >
          </v-icon>
          {{ $t("legenda_130") }}
        </h4>
      </div>
    </template>

    <template v-slot:body>
      <div class="m-3">
        <legendaTable
          style="overflow-y: auto"
          :customTableStyle="{ tableLightStyle: true }"
          :tableConfig="uploadTableConfig"
          :density="'compact'"
          v-on:removeFile="removeFile"
          v-on:viewDocument="openDocument"
          :mxHeight="calcHeight"
        ></legendaTable>
      </div>
    </template>
    <template v-slot:footer>
      <br />
    </template>
  </BaseModal>
</template>

<script>
import TableComponent from "../TableComponent.vue";
import BaseModal from "../ui/modals/BaseModal.vue";

export default {
  name: "UploadConfirmation",
  components: {
    legendaTable: TableComponent,
    BaseModal,
  },
  props: {
    uploadTableConfig: {},
  },
  computed: {
    calcHeight() {
      return window.innerHeight - 300;
    },
  },
  data() {
    return {
      uploadInProgress: false,
      closeModal: false,
    };
  },
  watch: {
    uploadTableConfig: {
      handler() {},
      deep: true,
    },
  },
  methods: {
    /**
     *
     */
    minimizeModal() {
      this.$emit("minimize");
    },
    /**
     *
     */
    removeFile(file) {
      this.$emit("removeFile", file.filename);
    },
    /**
     *
     */
    openDocument(file) {
      this.$emit("openDocument", file);
    },
  },
};
</script>

<style>
.tableLightStyle {
  border: solid;
  border-color: whitesmoke;
  border-width: medium;
  left: 0;
  right: 0;
  width: 100%;
}
</style>
