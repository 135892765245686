import axiosWrapper from "../../plugins/axios/axiosWrapper";
import { getFileSize } from "../../utils/fileUtils";

const getters = {
  statePdfs: (state) => state.pdfs,
  statePdfBinary: (state) => state.pdfBinary,
  stateFilesToUpload: (state) => state.filesToUpload,
  stateScopedFilesToUpload: (state) => state.scopedFilesToUpload,
  stateProgress: (state) => state.progress,
  statePolling: (state) => state.polling,
};

const getDefaultPdfState = () => {
  return {
    pdfs: null,
    pdfBinary: new ArrayBuffer(0),
    filesToUpload: [],
    scopedFilesToUpload: null,
    progress: [],
    polling: null,
  };
};

// initial state
const state = getDefaultPdfState();

const actions = {
  /***
   *
   */
  async getPdfs({ commit }, folder_id) {
    let data = await axiosWrapper.get(
      "/pdf_binary/all",
      null,
      { folder_id: folder_id },
      { "Content-Type": "application/json" }
    );

    commit("setPdfs", data);
  },

  /***
   *
   */
  async getPdfsByBatchId({ commit }, batchId) {
    let data = await axiosWrapper.get(
      "/pdf_binary/poll",
      null,
      { batch_id: batchId },
      { "Content-Type": "application/json" }
    );
    commit("setProgress", data);
  },

  /**
   *
   * @param {*} param0
   * @param {*} data
   */
  async getPdf({ commit }, documentId) {
    const response = await axiosWrapper.get(
      `/pdf_binary/`,
      null,
      { document_id: documentId },
      null,
      "arraybuffer"
    );
    const pdfBinaryArray = response.data;
    commit("setPdfBinary", pdfBinaryArray);
  },

  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  // eslint-disable-next-line no-empty-pattern
  async deletePdf({}, { folder_id, document_id }) {
    await axiosWrapper.delete(
      `/pdf_binary/`,
      null,
      {
        folder_id: folder_id,
        document_id: document_id,
      },
      { "Content-Type": "application/json" }
    );
  },

  /***
   *
   */
  // eslint-disable-next-line no-empty-pattern
  async storePdf({}, { folder_id, file, permissions }) {
    let formData = {
      file: file.file,
      folder_id: folder_id,
      //append the file path because the fastApi Upload file uses as file name the original one
      name: file.filePath,
      permissions: permissions,
    };

    const response = await axiosWrapper.postForm(`/pdf_binary/`, formData);
    return response.data.message;
  },

  /***
   *
   */
  // eslint-disable-next-line no-empty-pattern
  async shareDocument({}, { source_document_id, to_user_username }) {
    await axiosWrapper.post(`/pdf_binary/share_document`, {
      source_document_id: source_document_id,
      to_user_username: to_user_username,
    });
  },

  /***
   *
   */
  // eslint-disable-next-line no-empty-pattern
  async renameDocument({}, { documentId, newName }) {
    let formData = {
      new_name: newName,
    };
    const response = await axiosWrapper.putForm(
      `/pdf_binary/:document_id/rename`,
      formData,
      {
        document_id: encodeURIComponent(documentId),
      }
    );
    return response.data.document_metadata.name;
  },
  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  // eslint-disable-next-line no-empty-pattern
  async moveDocument({}, { documentId, folderId }) {
    await axiosWrapper.put(`/pdf_binary/move`, {
      document_id: documentId,
      folder_id: folderId,
    });
  },
};

const mutations = {
  /**
   *
   * @param {*} state
   * @param {*} data
   */
  setPdfs(state, data) {
    if (data) {
      state.pdfs = data.data.elements;
      // set the size for the dashboard table
      state.pdfs.forEach((file) => {
        const fileSizeObj = getFileSize(file.size, null, 0);
        file.byteSize = file.size;
        file.size = fileSizeObj.completeSize;
      });
    }
  },
  /**
   *
   * @param {*} state
   * @param {*} pdfBinary
   */
  setPdfBinary(state, pdfBinary) {
    state.pdfBinary = pdfBinary;
  },
  /**
   *
   * @param {*} state
   * @param {*} files
   */
  setScopedFilesToUpload(state, files) {
    state.scopedFilesToUpload = files;
    state.filesToUpload.push(...files);
  },

  /**
   *
   * @param {*} state
   * @param {*} data
   */
  setProgress(state, data) {
    // here map in the filesToUpload the state of the upload
    if (data.data) {
      state.progress = data.data.elements;
    }
  },
  /**
   *
   * @param {*} state
   * @param {*} polling
   */
  setPolling(state, polling) {
    state.polling = polling;
  },
  /**
   *
   * @param {*} state
   */
  resetPdfState(state) {
    Object.assign(state, getDefaultPdfState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
