<template>
  <BaseModal
    @keydown.escape.prevent="closeModal"
    @click:outside="closeModal"
    v-model="dialog"
    :persistent="persistent"
    @close="closeModal"
    @confirm="confirm"
    :modal-class="modalClass"
    hide-overlay
    :confirmLabel="okButtonLabel"
    :showCloseBtn="isConfirmationModal"
    :showConfirmBtn="true"
    :showCloseBtnHeader="false"
  >
    <template v-slot:header>
      <div class="d-flex align-items-center">
        <v-icon color="icon-primary">{{ icon }}</v-icon>
        <div class="text-h5 m-2 p-1" style="word-break: break-word">
          {{ title }}
        </div>
      </div>
    </template>

    <template v-slot:body>
      <v-card class="w-auto h-auto rounded-0" color="transparent">
        <v-card-text>
          <div>{{ $t(content) }}</div>
        </v-card-text>
      </v-card>
      <AlertComponent
        v-if="alertMessages"
        :density="'compact'"
        :variant="'warning'"
        :messageList="alertMessages"
      ></AlertComponent>
    </template>
    <template v-slot:footer>
      <br />
    </template>
  </BaseModal>
</template>

<script>
import AlertComponent from "../../AlertComponent.vue";
import BaseModal from "./BaseModal.vue";

export default {
  name: "ModalComponent",

  components: {
    BaseModal,
    AlertComponent,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "info",
    },
    okButtonLabel: {
      type: String,
      default: "legenda_040",
    },
    cancelButtonLabel: {
      type: String,
      default: "legenda_039",
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    customStyle: {
      type: String,
      default: "color: primary;",
    },
    icon: {
      type: String,
      default: null,
    },
    alertMessages: {
      type: Array,
      default: null,
    },
    modalClass: {
      type: String,
      default: "small-modal",
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    /**
     *
     */
    isConfirmationModal() {
      return this.type === "confirmation";
    },
    /**
     *
     */
    closeDialog() {
      return !this.dialog;
    },
  },
  methods: {
    /**
     *
     */
    confirm() {
      this.$emit("ok");
    },
    /**
     *
     */
    closeModal() {
      this.$emit("cancel");
    },
  },
};
</script>
