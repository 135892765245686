<template>
  <div>
    <v-alert
      :type="this.variant"
      :closable="closable"
      :density="density"
      v-model="alert"
      :color="color"
    >
      <template v-slot:text>
        <div v-for="message in messageList" :key="message">
          <p class="m-0 p-1" v-if="message.links">
            <i18n-t :keypath="message.text">
              <slot :name="index" v-for="(link, index) in message.links">
                <a
                  v-bind="props"
                  :key="index"
                  :href="link.href"
                  :target="link.openInTab"
                  >{{ $t(link.placeholder) }}</a
                >
              </slot>
            </i18n-t>
          </p>
          <p
            class="m-0"
            :class="{ 'p-1': messageList && messageList.length > 1 }"
            v-if="!message.links"
          >
            {{ $t(message.text) }}
          </p>
        </div>
      </template>
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {
    messageList: null,
    variant: null,
    link: null,
    closable: {
      type: Boolean,
      default: false,
    },
    density: {
      type: String,
      default: "default",
    },
    color: {
      type: String,
      default: null,
    },
  },
  watch: {
    link: {
      handler(newValue) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        console.log(newValue);
      },
      deep: true,
    },
  },

  data() {
    return {
      alert: true,
    };
  },
};
</script>
